<template>
  <div class="container">
    <div class="topbanner">
      <img :src="topimg" alt="" />
    </div>
    <div class="main">
      <div class="title"><span class="blue">发布需求</span></div>
      <el-form ref="form" :model="form" label-width="110px">
        <el-form-item label="公司名称：">
          <el-input v-model="form.company_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="公司地址：">
          <el-input v-model="form.company_address"></el-input>
        </el-form-item>
        <el-form-item label="需求类型：">
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="12">
              <el-select v-model="cstr1" placeholder="请选择" @change="change1">
                <el-option
                  :label="item.name"
                  :value="item.name"
                  v-for="(item, i) in category1"
                  :key="i"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <el-select v-model="cstr2" placeholder="请选择" @change="change2">
                <el-option
                  :label="item.name"
                  :value="item.name"
                  v-for="(item, i) in category2"
                  :key="i"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="服务内容：">
          <el-select
            v-model="form.product_bind_id"
            placeholder="请选择服务内容"
            @change="change3"
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="(item, i) in fwnr"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求详情：">
          <el-input
            type="textarea"
            v-model="form.description"
            rows="5"
          ></el-input>
        </el-form-item>

        <el-form-item label="补充图片：">
          <upload-img @input="getimg"></upload-img>
        </el-form-item>
        <el-form-item label="是否需要现场勘察：" label-width="180px">
          <el-radio-group v-model="form.need_xc">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex flexcenter">
          <el-button type="primary" @click="onSubmit">提交需求</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import uploadImg from "@c/common/uploadimg.vue";
export default {
  data() {
    return {
      form: {
        company_name: "",
        company_address: "",
        name: "",
        phone: "",
        category_name: "", //需求类型name
        product_name: "", //服务内容名称
        product_bind_id: "", //服务内容id
        description: "",
        images: [],
        need_xc: 1, //是否需要现场勘查:0=否,1=是
      },
      category1: [],
      category2: [],
      categoryid: "",
      cstr1: "",
      cstr2: "",
      topimg: "",
      fwnr: [],
      defaultimg: require("@img/thankyou.png"),
      id:"",
      isfwnr:false
    };
  },
  components: { uploadImg },
  computed: {
    ...mapState(["isLogin", "userInfo"]),
  },
  mounted() {
    var id=this.$route.query.id;
    this.$nextTick(()=>{
       this.scrollToTop();
    })
    if(id){
      this.id=id;
      this.$http.demandinitdata({
        product_id:id
      }).then((res) => {
         var data=res.data;
         this.form.company_name=data.company_name;
         if( data.company_address.constructor===Array){
            this.form.company_address=data.company_address.join(" ");
         }else{
           this.form.company_address=data.company_address;
         }
        
            //  this.change3(this.id);
         this.form.name=data.name;
        this.form.phone=data.phone;
        this.category1 = data.cates;
        this.findxqlx();
      });
    }else{
      this.$http.demandinitdata({
        product_id:""
      }).then((res) => {
         var data=res.data;
         this.form.company_name=data.company_name;
         if( data.company_address.constructor===Array){
            this.form.company_address=data.company_address.join(" ");
         }else{
           this.form.company_address=data.company_address;
         }
        
            //  this.change3(this.id);
         this.form.name=data.name;
        this.form.phone=data.phone;
        this.category1 = data.cates;
      
      });
      /* this.$http.demandcates().then((res) => {
          this.category1 = res.data;
        }); */
    }
    this.$http.demandimg().then((res) => {
      this.topimg = res.data.image;
    });
   
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
     scrollToTop(){
         window.scroll({ top: 0, left: 0});
     },
    findxqlx(){
       var index1=-1,index2=-1;
        this.category1.forEach((obj,i)=>{
          /*   if(obj.selected){
              index1=i;
              return
            } */
            obj.children.forEach((obj2,j)=>{
               if(obj2.selected){
                  index2=j;
                  index1=i;
                  // name=obj2.name;
                }
            })
        })
        this.cstr1=this.category1[index1].name;
        this.change1(this.cstr1);
        this.cstr2=this.category2[index2].name;
        this.change2(this.cstr2);
        // this.form.product_bind_id= Number(this.id);
        var inter=setInterval(()=>{
          if(this.isfwnr){
            this.change3(Number(this.id))
            clearInterval(inter);
          }  
        },100)
        
    },
    change1(val) {
      var index = this.category1.findIndex((obj) => obj.name == val);
      this.category2 = this.category1[index].children;
      this.cstr2 = "";
      this.fwnr=[];
      this.form.category_name = this.cstr1;
      this.categoryid = this.category1[index].id;
      this.form.product_bind_id="";
      // this.getfwnr();
    },
    change2(val) {
      var index = this.category2.findIndex((obj) => obj.name == val);
      this.form.category_name = this.cstr2;
      this.categoryid = this.category2[index].id;
      this.form.product_bind_id="";
      this.getfwnr();
    },
    change3(val) {
      var index = this.fwnr.findIndex((obj) => obj.id == val);
      this.form.product_name = this.fwnr[index].title;
      this.form.product_bind_id = this.fwnr[index].id;
    },
    getfwnr() {
      this.$http.getdemandproduct({
          category_id: this.categoryid,
        })
        .then((res) => {
          this.fwnr = res.data;
          this.isfwnr=true;
        });
    },
    getimg(str) {
      this.form.images = str.split(",");
    },
    onSubmit() {
       var str =
        "<div class=\"tip\"><img src=\"" +
        this.defaultimg +
        "\"/><div class=\"word1\">感谢您的信任</div><div class=\"word2\">我们的工作人员将会尽快和您取得联系， <br>请保持电话畅通！</div></div>";
      
     this.$http.demandsubmit(this.form).then((res) => {
        if (res.code == 0) {
          if (res.msg.indexOf("不可为空") > 0||res.msg.indexOf("缺少")>=0) {
            this.$message.error("全部必填，您还有信息没有填哦！");
          } else {
            this.$message.error({ message: res.msg });
          }
        } else if (res.code == 1) {
          //  this.$message.success(res.msg);
          //  history.back();
           this.$alert(str, "", {
            dangerouslyUseHTMLString: true,
            center: true,
            showConfirmButton: true,
            callback:()=>{
                this.$router.push({
                    path: "/my",
                  });
            }
          }); 
          
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  background: #fff;
  margin-bottom: 20px;
}
.el-button {
  font-size: 16px;
  width: 200px;
  margin-top: 30px;
}
/deep/.el-radio__label {
  font-size: 18px;
}

.el-form {
  /deep/.el-form-item__label {
    font-size: 18px;
    color: #333;
  }
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
/deep/.el-upload--picture-card {
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  cursor: pointer;

  vertical-align: top;
  border: none;
  background: none;
}
.addpic {
  width: 120px;
  height: 120px;
  background-color: #f1f1f1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  img {
    display: block;
  }
  .word {
    color: #999;
    font-size: 20px;
    line-height: 30px;
  }
}
.topbanner {
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: 380px;
    object-fit: cover;
  }
}
.el-select {
  width: 100%;
}
.main {
  padding: 30px 140px;
  .title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    .blue {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 25%;
        width: 50px;
        bottom: -4px;
        height: 2px;
        background-color: $color-theme;
      }
    }
  }
}
</style>
